<template>
  <div>
    <Toolbar class="mb-4">
      <template #left>
        <Button icon="pi pi-download" class="p-button-help" @click="exportCSV($event)" style="margin-right: 10px" />
        <Button icon="pi pi-print" @click="printPage()" label="Print" />

        <!-- <Button
          icon="pi pi-plus"
          class="p-button-success ml-2"
          @click="display = true"
        />

        <Dialog
          header="Add Product"
          :visible.sync="display"
          :style="{ width: '50vw' }"
        >
          <div class="p-m-0">
            <div class="mb-3">
              <label for="name" class="form-label required">
                Select Order Time
                <span>*</span>
              </label>

              <select
                class="form-select form-control"
                id="group_id"
                name="group_id"
                v-model.number="addObj"
                required
              >
                <option
                  v-for="item of listMassageOrder"
                  :key="item.id"
                  :value="item"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <template #footer>
            <Button label="Hinzufügen" icon="pi pi-check" @click="add" autofocus />
            <Button
              label="Cencel"
              icon="pi pi-times"
              @click="display = false"
              class="p-button-text"
            />
          </template>
</Dialog> -->
      </template>

      <template #right>
        <Button icon="pi pi-times" class="p-button-danger ml-2" @click="$router.go(-1)" />
      </template>
    </Toolbar>
    <div id="print">
      <DataTable ref="dt" :value="list" class="p-datatable-gridlines p-datatable-sm" showGridlines>
        <template #header>
          <div class="table-header">
            <!-- Massages Aufführen -->

            <div style="float: right">
              <!-- <Button
              icon="pi pi-download"
              class="p-button-help"
              @click="exportCSV($event)"
            /> -->
            </div>
          </div>
        </template>
        <template #empty>Keine Daten</template>
        <template #loading>Daten werden geladen, bitte warten... </template>

        <ColumnGroup type="header">
          <Row>
            <Column :rowspan="2" header="Bezeichnung" />
            <Column :rowspan="2" header="Zeit" />
            <Column :rowspan="2" header="Mit" />
            <Column :rowspan="2" header="Preise" />

            <Column :colspan="2" header="Hotelgast" />

            <Column :rowspan="2" header="Bar" />
            <Column :rowspan="2" header="Karte" />

            <Column :colspan="2" header="Gutschein" />
            <Column :colspan="2" header="10er Block" />

            <Column :rowspan="2" header="online" />
            <Column :rowspan="2" header="Produkte" />
          </Row>
          <Row>
            <Column header="Preise" />
            <Column header="Zimmer" />

            <Column header="Nr:." />
            <Column header="Betrag" />

            <Column header="Nr:." />
            <Column header="Betrag" />
          </Row>
        </ColumnGroup>

        <Column filterMatchMode="contains" field="name">
          <template #body="slotProps">
            {{ JSON.parse(slotProps.data.massageId.name).de }}
            <textarea @change="update($event.target.value, slotProps.data.id, 'rnote')" type="number"
              class="form-control no-print" v-model="slotProps.data.rnote" />
            <p class="yes-print">{{ slotProps.data.rnote }}</p>
          </template>
        </Column>
        <Column filterMatchMode="contains" field="Zeit">
          <template #body="slotProps">
            <span @click="$router.push('/admin/massageorder/edit/' + slotProps.data.id)" style="cursor: pointer;">
              {{ $durationFormatFull24(slotProps.data.start, slotProps.data.end) }}

            </span>
            <!-- <Button
              label="Delete"
              icon="pi pi-trash"
              @click="deleteItem(slotProps.data.id)"
              class="p-button-danger no-print"
            /> -->
          </template>
        </Column>
        <!-- Mitarbeiter -->
        <Column filterMatchMode="contains" field="adminsId.fullName" />

        <Column filterMatchMode="contains" field="Preise">
          <template #body="slotProps">
            <input @change="update($event.target.value, slotProps.data.id, 'Preise')" type="number"
              class="form-control no-print" v-model.number="slotProps.data.Preise" />
            <p class="yes-print">{{ slotProps.data.Preise }}</p>
          </template>
        </Column>

        <Column filterMatchMode="contains" field="HotelPreise">
          <template #body="slotProps">
            <input @change="
              update($event.target.value, slotProps.data.id, 'HotelPreise')
              " type="number" class="form-control no-print" v-model.number="slotProps.data.HotelPreise" />
            <p class="yes-print">{{ slotProps.data.HotelPreise }}</p>
          </template>
        </Column>

        <Column filterMatchMode="contains" field="hotel">
          <template #body="slotProps">
            <input @change="update($event.target.value, slotProps.data.id, 'hotel')" type="number"
              class="form-control no-print" v-model.number="slotProps.data.hotel" />
            <p class="yes-print">{{ slotProps.data.hotel }}</p>
          </template>
        </Column>

        <Column filterMatchMode="contains" field="Bar">
          <template #body="slotProps">
            <input @change="update($event.target.value, slotProps.data.id, 'Bar')" type="number"
              class="form-control no-print" v-model.number="slotProps.data.Bar" />
            <p class="yes-print">{{ slotProps.data.Bar }}</p>
          </template>
        </Column>

        <Column filterMatchMode="contains" field="Kartenzahlung">
          <template #body="slotProps">
            <input @change="
              update($event.target.value, slotProps.data.id, 'Kartenzahlung')
              " type="number" class="form-control no-print" v-model.number="slotProps.data.Kartenzahlung" />
            <p class="yes-print">{{ slotProps.data.Kartenzahlung }}</p>
          </template>
        </Column>
        <Column filterMatchMode="contains" field="giftsId">
          <template #body="slotProps">
            <Dropdown style="width: 100%" class="no-print" @change="
              update($event.value, slotProps.data.id, 'giftsId')
              " v-model.number="slotProps.data.giftsId" :options="giftList" optionLabel="name" optionValue="id"
              :filter="true" placeholder="Select a Gift id" :showClear="true">
              <template #value="slotProps">
                <div class="country-item country-item-value" v-if="slotProps.value">
                  <div>{{ slotProps.value }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
            </Dropdown>

            <p class="yes-print">{{ slotProps.data.giftsId }}</p>
          </template>
        </Column>

        <Column filterMatchMode="contains" field="GutsheinBetrag">
          <template #body="slotProps">
            <input @change="
              update($event.target.value, slotProps.data.id, 'GutsheinBetrag')
              " type="number" class="form-control no-print" v-model.number="slotProps.data.GutsheinBetrag" />
            <p class="yes-print">{{ slotProps.data.GutsheinBetrag }}</p>
          </template>
        </Column>

        <Column filterMatchMode="contains" field="packagesUsersId">
          <template #body="slotProps">
            <Dropdown style="width: 100%" class="no-print" @change="
              update(
                $event.value,
                slotProps.data.id,
                'packagesUsersId'
              )
              " v-model.number="slotProps.data.packagesUsersId" :options="packagesUsersList" optionLabel="name"
              optionValue="id" :filter="true" placeholder="Select a Package id" :showClear="true">
              <template #value="slotProps">
                <div class="country-item country-item-value" v-if="slotProps.value">
                  <div>{{ slotProps.value }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
            </Dropdown>

            <p class="yes-print">{{ slotProps.data.packagesUsersId }}</p>
          </template>
        </Column>

        <Column filterMatchMode="contains" field="BlockBetrag">
          <template #body="slotProps">
            <input @change="
              update($event.target.value, slotProps.data.id, 'BlockBetrag')
              " type="number" class="form-control no-print" v-model.number="slotProps.data.BlockBetrag" />
            <p class="yes-print">{{ slotProps.data.BlockBetrag }}</p>
          </template>
        </Column>
        <Column filterMatchMode="contains" field="online">
          <template #body="slotProps">
            <input @change="update($event.target.value, slotProps.data.id, 'online')" type="number"
              class="form-control no-print" v-model.number="slotProps.data.online" />
            <p class="yes-print">{{ slotProps.data.online }}</p>
          </template>
        </Column>

        <Column filterMatchMode="contains" field="Produkte">
          <template #body="slotProps">
            <input @change="
              update($event.target.value, slotProps.data.id, 'Produkte')
              " type="number" class="form-control no-print" v-model.number="slotProps.data.Produkte" />
            <p class="yes-print">{{ slotProps.data.Produkte }}</p>
          </template>
        </Column>

        <ColumnGroup type="footer">
          <Row>
            <Column footer=" " :footerStyle="{ 'text-align': 'center' }" />
            <Column footer=" " :footerStyle="{ 'text-align': 'center' }" />

            <Column footer=" " :footerStyle="{ 'text-align': 'center' }" />

            <Column :footer="list
              .map((el) => el.Preise)
              .reduce((a, b) => Number(a) + Number(b), 0)
              " :footerStyle="{ 'text-align': 'center' }" />

            <Column :footer="list
              .map((el) => el.HotelPreise)
              .reduce((a, b) => Number(a) + Number(b), 0)
              " :footerStyle="{ 'text-align': 'center' }" />

            <Column :footer="''" :footerStyle="{ 'text-align': 'center' }" />

            <Column :footer="list
              .map((el) => el.Bar)
              .reduce((a, b) => Number(a) + Number(b), 0)
              " :footerStyle="{ 'text-align': 'center' }" />
            <Column :footer="list
              .map((el) => el.Kartenzahlung)
              .reduce((a, b) => Number(a) + Number(b), 0)
              " :footerStyle="{ 'text-align': 'center' }" />
            <Column footer="" :footerStyle="{ 'text-align': 'center' }" />
            <Column :footer="list
              .map((el) => el.GutsheinBetrag)
              .reduce((a, b) => Number(a) + Number(b), 0)
              " :footerStyle="{ 'text-align': 'center' }" />
            <Column footer="" :footerStyle="{ 'text-align': 'center' }" />
            <Column :footer="list
              .map((el) => el.BlockBetrag)
              .reduce((a, b) => Number(a) + Number(b), 0)
              " :footerStyle="{ 'text-align': 'center' }" />
            <Column :footer="list
              .map((el) => el.online)
              .reduce((a, b) => Number(a) + Number(b), 0)
              " :footerStyle="{ 'text-align': 'center' }" />
            <Column :footer="list
              .map((el) => el.Produkte)
              .reduce((a, b) => Number(a) + Number(b), 0)
              " :footerStyle="{ 'text-align': 'center' }" />
          </Row>
        </ColumnGroup>
      </DataTable>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      loading: true,

      addObj: null,
      HotelPreise: true,
      HotelZN: true,
      Bar: true,
      kartekarte: true,
      Gutshein: true,
      karteVisa: true,
      kurs: true,
      online: true,

      display: false,
      listMassageOrder: [],
      giftList: [],
      packagesUsersList: [],
    };
  },
  methods: {
    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("print").innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(
        this.$htmlPrint2(stylesHtml, prtHtml, this.$route.params.day)
      );
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    getData() {
      this.$http.post(`appointments/myappointments?isR=0&date=${this.$route.params.day}`, {}).then(
        (response) => {
          this.loading = false;

          let list = response.data.data;
          for (let item of list) {
            item.giftsId = item.giftsId ? item.giftsId.id : null;
            item.packagesUsersId = item.packagesUsersId
              ? item.packagesUsersId.id
              : null;
          }
          this.list = list;

          
          let listGiftsIds = this.list.map((el) => el.giftsId).filter(el=>el);

          this.$http.get(`gifts?ended=0`).then((responseX) => {
            const listX = responseX.data.data;

            for (let item of listX) {
              item.name =
                this.$codePadding(item.id) + " " + item.fname + " " + item.lname;
            }

            this.giftList = listX;
          });

          let listPackagesUsersIds = this.list.map((el) => el.packagesUsersId).filter(el=>el);

          

          this.$http.get(`packages_users`).then((responseY) => {
            const listY = responseY.data.data;

            for (let item of listY) {
              item.name =
                this.$codePadding(item.id) + " " + item.fname + " " + item.lname;
            }

            this.packagesUsersList = listY;
          });
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );


    },
    deleteItem(id) {
      this.$http.get(`deletereportsbyday/${id}`).then(
        (response) => {
          this.$toast.add({
            severity: "success",
            summary: "Done Successfully",
            detail: "Updated Successfully",
            life: 3000,
          });
          this.getData();
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    },

    update(val, id, col) {
      console.log("vvvalll", val)
      let obj = {};
      obj[col] = val;
      this.$http.put(`appointments/${id}`, obj).then(
        (response) => {
          this.$toast.add({
            severity: "success",
            summary: "Done Successfully",
            detail: "Updated Successfully",
            life: 3000,
          });
          // this.getData();
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    },
    add() {
      if (this.addObj) {
        this.$http.post(`reportsbydayAdd`, this.addObj).then(
          (response) => {
            this.addObj = null;
            this.display = false;
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Added Successfully",
              life: 3000,
            });
            this.getData();
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
      }
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    xc(value) {
      if (value) {
        return JSON.parse(value);
      }
      return [];
    },
  },
  created() {
    this.getData();


  },
};
</script>

<style>
.xxx-checkbox .p-checkbox {
  margin-bottom: 22px;
  margin-right: 5px;
}

.fc .fc-view-harness {
  /* height: 856.296px !important; */
}

.fc .fc-scrollgrid-section table {
  width: 100% !important;
  /* height: 825px !important; */
}

.fc-scrollgrid-section-body .fc-scrollgrid-section table {
  width: 100% !important;
  /* height: 856.296px !important; */
}

.yes-print {
  display: none;
}
</style>
